.App {
	min-height: 100vh;
	background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

body {
	min-width: 360px;
}

.search-form {
	min-height: 10vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-bar {
	width: 50%;
	border: none;
	padding: 10px;
}

.search-button {
	background: lightcoral;
	border: none;
	padding: 10px 20px;
	color: white;
}

.recipes {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
